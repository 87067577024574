import React, {useState} from "react";
import './App.css'
import "rapidoc";
import {IconBook, IconWorldSearch, IconWorldWww} from "@tabler/icons-react";

const links = [
    {url: 'https://pirichain.com', icon: <IconWorldWww />},
    {url: 'https://whitepaper.pirichain.com', icon: <IconBook />},
    {url: 'https://piriscan.com', icon: <IconWorldSearch />},
]

const CLink = ({param}) => {
    return (
        <a
            href={param.url}
            rel={"noreferrer"}
            target={"_blank"}
            style={{
                textDecoration: 'none',
                color: "#fff",
                fontWeight: 700
            }}>
            {param.icon}
        </a>
    )
}

export default function App() {
    const theme = localStorage.getItem('theme')
    const [colorTheme, setColorTheme] = useState(theme !== null ? theme : "light")
    const [wide, setWide] = useState(window.innerWidth >= 768);
    window.addEventListener("resize", () => setWide(window.innerWidth >= 768));
    return (
        <rapi-doc
            style={{height: "100vh", width: "100%"}}
            spec-url="./lib/api.json"
            render-style={wide ? "focused" : "view"}
            regular-font={"'Satoshi-Variable', sans-serif"}
            allow-authentication={false}
            update-route={true}
            route-prefix={""}
            theme={colorTheme}
            nav-bg-color={colorTheme === 'dark' ? "#353535" : "#f5f5f5"}
            show-method-in-nav-bar={"as-colored-text"}
            show-header={true}
            allow-spec-url-load={false}
            allow-spec-file-load={false}
            allow-spec-file-download={false}
            allow-schema-description-expand-toggle={false}
            allow-advanced-search={false}
            on-nav-tag-click={"expand-collapse"}
            nav-active-item-marker={"colored-block"}
            nav-accent-text-color={"#cc3901"}
            schema-expand-level={1}
        >
            <img slot={"logo"} src={"./header-logo.png"} alt={"Pirichain Technology"}/>
            <img slot={"nav-logo"} src={colorTheme === 'dark' ? "./piriapidoc-bg-dark.png" : "./piriapidoc-bg-light.png"}
                 alt={"Pirichain Technology"}
                 style={{width: 'auto', height: 'auto', alignSelf: 'center'}}/>
            <div slot={"nav-logo"} style={{alignSelf: 'center', marginBottom: 10, width: '86%'}}>
                <button
                    type={"button"}
                    style={{
                        width: '100%',
                        paddingLeft: 25,
                        paddingRight: 25,
                        paddingTop: 7,
                        paddingBottom: 7,
                        fontWeight: 600,
                        borderRadius: 5,
                        borderWidth: 0,
                        cursor: 'pointer',
                        backgroundColor: colorTheme === 'dark' ? '#d2d2d2' : '#5b5b5b',
                        color: colorTheme === 'dark' ? '#333' : '#ececec'
                    }}
                    onClick={() => setColorTheme(prevState => {
                        localStorage.setItem('theme', prevState === 'light' ? 'dark' : 'light')
                        return prevState === 'light' ? 'dark' : 'light'
                    })}>
                    {`Switch to ${colorTheme === 'light' ? '🌙' : '☀️'} Mode`}
                </button>
            </div>
            <div slot={"header"} style={{width: '100%'}}>
                <div style={{flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: wide ? 'flex-end' : 'flex-start', columnGap: 20}}>
                    {wide ? null : <img src={"https://static.pirichain.com/logo/transparent/36"} alt={"Pirichain Technology"}/>}
                    {links.map((item, index) => <CLink key={index} param={item}/>)}
                </div>
            </div>
        </rapi-doc>
    );
}
